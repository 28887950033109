import React, { Component } from "react";

//CSS
import "../assets/styles/pages/governanca.scss";

//Componentes
import Servico from "../components/Servicos/ServiceComponent";
import FaleConosco from "../components/Servicos/FaleConoscoComponent";

//Imagens
import governanca1 from "../assets/images/governanca/governanca1.png";
import governanca2 from "../assets/images/governanca/governanca2.png";
import governanca3 from "../assets/images/governanca/governanca3.png";

class Governanca extends Component {
  render() {
    return (
      <div id="governanca">
        <Servico
          icon="business"
          title="Governança de TI"
          descricao={`Estamos aptos a promover e garantir a segurança das informações, disponibilidade dos serviços e continuidade de negócios, fornecendo serviços com base nas melhores práticas do mercado. `}
        />
        <div className="section animated fadeInUp">
          <div className="container">
            <div className="columns is-vcentered governanca">
              <div className="column is-6">
                <img src={governanca1} alt="" />
              </div>
              <div className="column is-5 is-offset-1">
                <p className="titulo">Destaque seus negócios</p>
                <p className="descricao">
                  Você que procura dar destaque ao seu negócio e chegar na
                  frente na corrida empresarial, aposte nas ferramentas de TI.
                  Elas auxiliam no processo de gestão e controle, além de
                  garantir a qualidade dos serviços e produtos ofertados.
                </p>
              </div>
            </div>

            <div className="columns is-vcentered governanca is-hidden-mobile">
              <div className="column is-6">
                <p className="titulo">
                  Aumente a competitividade de sua empresa
                </p>
                <p className="descricao">
                  Agora ficou ainda mais fácil idealizar estratégias para
                  aumentar sua competitividade: Por meio de um conjunto de
                  práticas que utilizam softwares e sistemas que se adequam aos
                  objetivos e regras da sua organização. Conheça já como a
                  Governança de TI pode ser utilizada em sua empresa e alcance
                  excelentes resultados para o seu negócio.
                </p>
              </div>
              <div className="column is-5 is-offset-1">
                <img src={governanca2} alt="" />
              </div>
            </div>

            {/* Visivel apenas no Mobile, por causa da ordem das imagens */}
            <div className="columns is-vcentered governanca is-hidden-tablet">
              <div className="column is-6">
                <img src={governanca2} alt="" />
              </div>
              <div className="column is-5 is-offset-1">
                <p className="titulo">
                  Aumente a competitividade de sua empresa
                </p>
                <p className="descricao">
                  Agora ficou ainda mais fácil idealizar estratégias para
                  aumentar sua competitividade: Por meio de um conjunto de
                  práticas que utilizam softwares e sistemas que se adequam aos
                  objetivos e regras da sua organização. Conheça já como a
                  Governança de TI pode ser utilizada em sua empresa e alcance
                  excelentes resultados para o seu negócio.
                </p>
              </div>
            </div>

            <div className="columns is-vcentered governanca final">
              <div className="column is-6">
                <img src={governanca3} alt="" />
              </div>
              <div className="column is-5 is-offset-1">
                <p className="titulo">Suporte técnico especializado</p>
                <p className="descricao">
                  Propomos soluções completas e customizadas de telefonia,
                  armazenamento de dados, gerenciamento e monitoramento do
                  parque de servidores e estações. Contamos com suporte técnico
                  espacializado, com monitoramento de Infraestrutura e Support
                  By Report.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <FaleConosco
          textButton={`Se você gostaria de ter essa solução, fale conosco para mais detalhes:`}
        />
      </div>
    );
  }
}

export default Governanca;
