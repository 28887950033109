import React, { Component } from "react";

//CSS
import "../assets/styles/pages/desenvolvimento.scss";

//Componentes Desenvolvimento
import Servico from "../components/Servicos/ServiceComponent";
import FaleConosco from "../components/Servicos/FaleConoscoComponent";
// import Ad                   from '../components/Ad';
// import Button               from '../components/general/Button';
import Clube from "../components/Servicos/desenvolvimento/ClubeVantagens";
import CAS from "../components/Servicos/desenvolvimento/CAS";
import Aminthas from "../components/Servicos/desenvolvimento/Aminthas";
import Pajucara from "../components/Servicos/desenvolvimento/Pajucara";
import PioNet from "../components/Servicos/desenvolvimento/PioNet";
import GetinfoTreinamentos from "../components/Servicos/desenvolvimento/GetinfoTreinamentos";
// import Amadeus from "../components/Servicos/desenvolvimento/Amadeus";

//Imagens
// import adschole      from "../assets/images/schole.png";

class Desenvolvimento extends Component {
  render() {
    return (
      <div id="desenvolvimento">
        <Servico
          icon="devices_other"
          title="Desenvolvimento de Software"
          descricao={`Trata-se do processo de desenvolvimento de um produto de software, seja ele para web ou mobile. Você tem uma ideia e deseja por em prática? Não importa a plataforma, nós estamos prontos!`}
        >
          <Clube />
          <CAS />
          <Aminthas />
          <Pajucara />
          {/* <Amadeus /> */}
          <PioNet />
          <GetinfoTreinamentos />
        </Servico>
        <hr className="margin-hr" />
        <FaleConosco
          textButton={`Se você precisa desenvolver projeto para web, android ou iOS, fale conosco:`}
        />
      </div>
    );
  }
}

export default Desenvolvimento;
