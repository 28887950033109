import React from "react";
import { Columns, Column } from "bloomer";
import { NavLink } from "react-router-dom";

const Footer = () => (
  <div id="footer" className="animated fadeIn">
    <div className="section">
      <div className="container">
        <Columns>
          <Column>
            <h1>Serviços</h1>
            <ul>
              <li>
                <NavLink to="/servicos/desenvolvimento">
                  Desenvolvimento de Software
                </NavLink>
              </li>
              <li>
                <NavLink to="/servicos/business">
                  Business Intelligence (BI)
                </NavLink>
              </li>
              <li>
                <NavLink to="/servicos/governanca">Governança de TI</NavLink>
              </li>
            </ul>
          </Column>
          <Column>
            <h1>Treinamentos</h1>
            <ul>
              <li>
                <NavLink to="/treinamentos/agile">
                  Thinking Agile (Gestão Ágil)
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/treinamentos/android">Android - Módulo 1</NavLink>
              </li>
              <li>
                <NavLink to="/treinamentos/iso-20000">ISO 20000</NavLink>
              </li>
              <li>
                <NavLink to="/treinamentos/iso-27002">ISO 27002</NavLink>
              </li>
              <li>
                <NavLink to="/treinamentos/itil">ITIL Foundation</NavLink>
              </li> */}
            </ul>
          </Column>
          <Column>
            <h1>Contato</h1>
            <ul>
              <li>
                <p>
                  <strong>Sede</strong>
                  <br />
                  Rua Zaqueu Brandão, 226, Bairro São José
                  <br />
                  CEP 49015-330 | Aracaju - SE
                  <br />
                  <br />
                  <strong>SergipeTec</strong>
                  <br /> Av. José Conrado de Araujo, 731, Bairro Rosa Elze,
                  SergipeTec - Bloco B3 - Sala 06
                  <br />
                  CEP 49100-000 | São Cristóvão - SE
                </p>
              </li>
              <li>
                <p>
                  <strong>Telefone</strong>
                  <br />
                  (79) 3022-9002
                </p>
              </li>
              <li>
                <a href="mailto:contato@getinfo.net.br">
                  contato@getinfo.net.br
                </a>
              </li>
            </ul>
          </Column>
        </Columns>
      </div>
    </div>
    <hr />
    <div className="section">
      <div className="container">
        <Columns className="final-footer">
          <Column>
            <p className="center">
              © Copyright 2021 | Todos os direitos reservados | Design e
              desenvolvimento por Getinfo.
            </p>
          </Column>
        </Columns>
      </div>
    </div>
  </div>
);

export default Footer;
