import React, { Component } from "react";
import { Columns, Column } from "bloomer";
import { AndroidIcon, AppleIcon, CodeTagsIcon } from "mdi-react";
import Button from "./../../components/general/Button";

import ScrollAnimation from "react-animate-on-scroll";

// Assets
import cellphones from "./../../assets/images/bg2.png";

export default class Desenvolvimento extends Component {
  render() {
    return (
      <div id="dev-schole-spa-infra-getinfo">
        <div className="section">
          <div className="container">
            <Columns>
              <ScrollAnimation animateIn="fadeIn">
                <Column className="img-column">
                  <img src={cellphones} alt="" />
                </Column>
              </ScrollAnimation>
              <Column>
                <Column>
                  <ScrollAnimation animateIn="fadeIn">
                    <h1>
                      Desenvolvemos software,
                      <br />
                      não importa a plataforma,
                      <br />
                      nós estamos prontos.
                    </h1>
                  </ScrollAnimation>
                </Column>
                <Column>
                  <ScrollAnimation animateIn="fadeInRight">
                    <p className="icons">
                      <AppleIcon />
                      <span>Apple</span>
                    </p>
                  </ScrollAnimation>
                </Column>
                <Column>
                  <ScrollAnimation animateIn="fadeInRight">
                    <p className="icons">
                      <AndroidIcon />
                      <span>Android</span>
                    </p>
                  </ScrollAnimation>
                </Column>
                <Column>
                  <ScrollAnimation animateIn="fadeInRight">
                    <p className="icons">
                      <CodeTagsIcon />
                      <span>Web</span>
                    </p>
                  </ScrollAnimation>
                </Column>
                <Column>
                  <ScrollAnimation animateIn="fadeInRight">
                    <Button link="/servicos/desenvolvimento">
                      Saiba mais <i className="material-icons">arrow_forward</i>
                    </Button>
                  </ScrollAnimation>
                </Column>
              </Column>
            </Columns>
          </div>
        </div>
      </div>
    );
  }
}
