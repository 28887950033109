import React, { Component } from "react";

import Banner from "../components/home/Banner";
import Quadros from "../components/home/Quadros";
import Desenvolvimento from "../components/home/Desenvolvimento";
import Treinamentos from "../components/home/Treinamentos";
import Clientes from "../components/home/Clientes";
import Getinfo from "../components/home/Getinfo";

export default class Home extends Component {
  render() {
    return (
      <section id="home">
        <Banner />
        <Quadros />
        <Desenvolvimento />
        <hr />
        <Treinamentos />
        <hr />
        <Clientes />
        <hr />
        <Getinfo />
      </section>
    );
  }
}
