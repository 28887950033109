import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";

import Header from "./components/general/Header";
import Footer from "./components/general/Footer";

import Blog from "./views/Blog";
import BlogPost from "./views/BlogPost";
import Business from "./views/Business";
import Governanca from "./views/Governanca";
import Contato from "./views/Contato";
import Desenvolvimento from "./views/Desenvolvimento";
import Home from "./views/Home";
// import Schole             from "./views/Schole";
// import Spa                from './views/Spa';
import Curriculos from "./views/Curriculos";
import Covid from "./views/Covid";
import Treinamentos from "./views/Treinamentos";

// import TreinamentoAndroid from "./views/TreinamentoAndroid";
// import TreinamentoIso20000 from "./views/TreinamentoIso20000";
// import TreinamentoIso27002 from "./views/TreinamentoIso27002";
// import TreinamentoItil from "./views/TreinamentoItil";
import TreinamentoAgile from "./views/TreinamentoAgile";
import Inscricao from "./views/Inscricao";

// import Amadeus from "./components/Servicos/desenvolvimento/paginas/AmadeusPagina";
import CAS from "./components/Servicos/desenvolvimento/paginas/CASPagina";
import Cartorio from "./components/Servicos/desenvolvimento/paginas/CartorioAminthas";
import Pajucara from "./components/Servicos/desenvolvimento/paginas/PajucaraPagina";
import PioNet from "./components/Servicos/desenvolvimento/paginas/PioNetPagina";
import AppTreinamentos from "./components/Servicos/desenvolvimento/paginas/TreinamentosPagina";

import PageNotFound from "./views/404";

import "./assets/styles/index.scss";

const Main = () => (
  <Router>
    <ScrollToTop>
      <main>
        <Header />
        <div className="main">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              exact
              path="/servicos/desenvolvimento"
              component={Desenvolvimento}
            />
            {/* <Route
              exact
              path="/servicos/desenvolvimento/amadeus"
              component={Amadeus}
            /> */}
            <Route exact path="/servicos/desenvolvimento/cas" component={CAS} />
            <Route
              exact
              path="/servicos/desenvolvimento/cartorio-aminthas"
              component={Cartorio}
            />
            <Route
              exact
              path="/servicos/desenvolvimento/pajucara"
              component={Pajucara}
            />
            <Route
              exact
              path="/servicos/desenvolvimento/pionet"
              component={PioNet}
            />
            <Route
              exact
              path="/servicos/desenvolvimento/treinamentos"
              component={AppTreinamentos}
            />
            <Route exact path="/servicos/business" component={Business} />
            <Route exact path="/servicos/governanca" component={Governanca} />
            {/*<Route exact path='/produtos/schole' component={Schole} />
            <Route exact path='/produtos/spa' component={Spa} />*/}
            <Route exact path="/trabalhe-conosco" component={Curriculos} />
            <Route exact path="/covid-19" component={Covid} />
            <Route exact path="/treinamentos" component={Treinamentos} />
            <Route
              exact
              path="/treinamentos/agile"
              component={TreinamentoAgile}
            />
            {/* <Route
              exact
              path="/treinamentos/android"
              component={TreinamentoAndroid}
            />
            <Route
              exact
              path="/treinamentos/iso-20000"
              component={TreinamentoIso20000}
            />
            <Route
              exact
              path="/treinamentos/iso-27002"
              component={TreinamentoIso27002}
            />
            <Route
              exact
              path="/treinamentos/itil"
              component={TreinamentoItil}
            /> */}
            <Route exact path="/treinamentos/inscricao" component={Inscricao} />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog/:slug/:id/" component={BlogPost} />
            <Route exact path="/contato" component={Contato} />
            <Route component={PageNotFound} />
          </Switch>
        </div>
        <Footer />
      </main>
    </ScrollToTop>
  </Router>
);

export default Main;
