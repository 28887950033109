import React, { Component } from "react";

//Componentes
import AndroidIos from "./../desenvolvimento/AndroidIos";
import Button from "./../../general/Button";
import Card from "./../desenvolvimento/CardComponent";

//images
import pionet from "../../../assets/images/desenvolvimento/piox.png";

//CSS
import "../../../assets/styles/components/Servicos/desenvolvimento/pioNet.scss";

class PioNET extends Component {
  render() {
    return (
      <div id="pionet" className="animated fadeInUp">
        <Card
          color="#019971"
          title="PioNET"
          text={`Sempre buscando avançar no tocante a tecnologia, a faculdade Pio Décimo 
                  disponibiliza o PioNET Mobile para Android e iOS. Esse app disponibiliza para 
                  os alunos da faculdade o acesso à: notas e faltas, horários, avisos e pendências.`}
          icons={<AndroidIos />}
          column="6"
          image={pionet}
        >
          <Button link="/servicos/desenvolvimento/pionet">Ver mais</Button>
        </Card>
      </div>
    );
  }
}

export default PioNET;
