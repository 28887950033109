import React from "react";
import { NavLink } from "react-router-dom";

//CSS
import "../../../../assets/styles/components/Servicos/desenvolvimento/projeto.scss";

const Projeto = props => (
  <div id="projeto" className="column is-3">
    <NavLink className="box" to={props.link}>
      <div className="title-height">
        <p className="title-card">{props.title}</p>
      </div>
      <br />
      <img className="image-card" src={props.image} alt="" />
    </NavLink>
  </div>
);

export default Projeto;
