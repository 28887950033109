import React, { Component } from "react";
import { Columns, Column } from "bloomer";
import Button from "./../../components/general/Button";

import ScrollAnimation from 'react-animate-on-scroll';

// Assets
import treinamentos from "./../../assets/images/treinamentos/treinamentos.png";

export default class Treinamentos extends Component {
  render() {
    return (
      <div id="dev-schole-spa-infra-getinfo"  className="treinamentos">
        <div className="section">
          <div className="container">
            <Columns>
              <Column>
                <Column>
                  <ScrollAnimation animateIn="fadeInLeft">
                    <h1>Getinfo Treinamentos</h1>
                  </ScrollAnimation>
                </Column>
                <Column>
                  <ScrollAnimation animateIn="fadeInLeft">
                    <p className="description">Invista em sua carreira e aperfeiçoe seu conhecimento, venha para o time Getinfo. Aqui nós temos o treinamento que você precisa para tirar sua certificação.</p>
                  </ScrollAnimation>
                </Column>
                <Column>
                  <ScrollAnimation animateIn="fadeInLeft">
                    <Button link="/treinamentos">Saiba mais <i className="material-icons">arrow_forward</i></Button>
                  </ScrollAnimation>
                </Column>
              </Column>
              <Column className="img-column">
                <ScrollAnimation animateIn="fadeInRight">
                  <img src={treinamentos} alt=""/>
                </ScrollAnimation>
              </Column>
            </Columns>
          </div>
        </div>
      </div>
    );
  }
}
