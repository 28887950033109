import React, { Component } from "react";
import { Columns, Column } from "bloomer";
import { NavLink } from "react-router-dom";

import ScrollAnimation from 'react-animate-on-scroll';

export default class Quadros extends Component {
  render() {
    return (
      <ScrollAnimation animateIn="fadeIn" delay={1000}>
        <div id="quadros">
          <div className="section">
            <div className="container">
              <Columns className="box">
                <Column>
                  <NavLink to="/servicos/desenvolvimento">
                    <i className="material-icons">devices_other</i>
                    <p>Desenvolvimento de Software</p>
                  </NavLink>
                </Column>
                <hr/>
                <Column className="border-center">
                  <NavLink to="/servicos/governanca">
                    <i className="material-icons">business</i>
                    <p>Governança de<br />TI</p>
                  </NavLink>
                </Column>
                <hr/>
                <Column>
                  <NavLink to="/servicos/business">
                    <i className="material-icons">multiline_chart</i>
                    <p>Business Intelligence</p>
                  </NavLink>
                </Column>
              </Columns>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    );
  }
}
