import React from "react";
import { NavLink } from "react-router-dom";

//CSS
import "../../assets/styles/components/Servicos/faleConoscoComponent.scss";

const Servico = props => {
  return (
    <div id="fale-conosco" className="animated fadeIn">
      <div className="section">
        <div className="container">
          <div className="columns is-centered pagina">
            <div className="column is-8">
              <p className="has-text-centered texto">{props.textButton}</p>
              <br />
              <div className="has-text-centered">
                <NavLink className="button is-primary contato" to="/contato">
                  CONTATO
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servico;
