import React from "react";

//CSS
import "../../../../assets/styles/components/Servicos/desenvolvimento/mais-projetos.scss";

const MaisProjetos = props => (
  <div id="mais-projetos">
    <hr />
    <div className="section">
      <div className="container">
        <div id="projetos">
          <p className="title-projetos">Outros Projetos</p>
          <div className="columns is-centered">{props.children}</div>
        </div>
      </div>
    </div>
  </div>
);

export default MaisProjetos;
