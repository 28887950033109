import React, { Component } from "react";

//Componentes
import AndroidIos from "./../desenvolvimento/AndroidIos";
import Button from "./../../general/Button";
import Card from "./../desenvolvimento/CardComponent";

//images
import pajucara from "../../../assets/images/desenvolvimento/pajucara.png";

//CSS
import "../../../assets/styles/components/Servicos/desenvolvimento/pajucara.scss";

class Pajucara extends Component {
  render() {
    return (
      <div id="pajucara" className="animated fadeInUp">
        <Card
          click={() => this.modalPajucara}
          color="#4a4a4a"
          title="Pajuçara"
          text={`O Aplicativo Pajuçara reúne todo o conteúdo multiplataformas do Pajuçara 
                  Sistema de Comunicação e oferece o maior conteúdo digital de Alagoas. 
                  O app reúne informação e entretenimento, com conteúdo interativo e em tempo 
                  real e foi desenvolvido nativamente para os sistemas iOS e Android.`}
          icons={<AndroidIos />}
          column="8"
          image={pajucara}
        >
          <Button link="/servicos/desenvolvimento/pajucara">Ver mais</Button>
        </Card>
      </div>
    );
  }
}

export default Pajucara;
