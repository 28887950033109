import React from "react";
import "../../assets/styles/components/blog/artigo-destaque.scss";

const ArtigoDestaque = (props) => {
  return (
    <a href={props.link}>
      <div id="artigo-destaque">
        <div className="column has-text-centered">
          <img src={props.src} alt={props.alt} />
        </div>
        <div className="column">
          <h1 className="title">{props.title}</h1>
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: `${props.text}` }}
          />
        </div>
      </div>
    </a>
  );
};

export default ArtigoDestaque;
