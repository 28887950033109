import React, {Component} from 'react';

//Componentes
import Button             from './../../general/Button';
import Card               from './../desenvolvimento/CardComponent';
import Web                from './../desenvolvimento/Web';

//images
import HomeAminthas   from '../../../assets/images/desenvolvimento/aminthas.png';

class Aminthas extends Component{
  render(){
    return(
      <div className="animated fadeInUp">
        <Card
          color="#4a90e2"
          title="Cartório Aminthas Garcez"
          text={`O site do Cartório Aminthas Garcez dispõe de todos os serviços que o cartório oferece. Além de permitir ao usuário, efetuar consultas de registro, firma e segunda via de aviso. O site é responsivo e se adapta em telas de qualquer dispositivo, sendo ele desktop ou mobile.`}
          icons={<Web />}
          column="8"
          image={HomeAminthas}
        >
          <Button link="/servicos/desenvolvimento/cartorio-aminthas">Ver mais</Button>
        </Card>
      </div>
    );
  }
}

export default Aminthas;