import React, { Component } from "react";

//Componentes
import Imagem from "../../../ImagemComponent";
import Info from "../InfoComponent";
import MaisProjetos from "./MaisProjetos";
import Projeto from "./Projeto";
import DownloadApp from "./../../../DownloadApp";

//Imagens
import portal from "../../../../assets/images/desenvolvimento/Pajucara/pajucara6.png";
import principal from "../../../../assets/images/desenvolvimento/Pajucara/pajucara1.png";
import radios from "../../../../assets/images/desenvolvimento/Pajucara/pajucara4.png";
import reporter from "../../../../assets/images/desenvolvimento/Pajucara/pajucara5.png";
import tv from "../../../../assets/images/desenvolvimento/Pajucara/pajucara3.png";
import veiculos from "../../../../assets/images/desenvolvimento/Pajucara/pajucara2.png";

import amadeus from "../../../../assets/images/desenvolvimento/MaisProjetos/amadeus.png";
import aminthas from "../../../../assets/images/desenvolvimento/MaisProjetos/aminthas.png";
import piox from "../../../../assets/images/desenvolvimento/MaisProjetos/piox.png";
import treinamentos from "../../../../assets/images/desenvolvimento/MaisProjetos/treinamentos.png";

//CSS
import "../../../../assets/styles/components/Servicos/desenvolvimento/pajucara.scss";

class Pajucara extends Component {
  render() {
    return (
      <div id="pajucara" className="animated fadeIn">
        <Info
          title="Pajuçara"
          color="rgba(0, 0, 0, 0.87)"
          descricao="O Aplicativo Pajuçara reúne todo o conteúdo multiplataformas do Pajuçara Sistema de Comunicação e oferece o maior conteúdo digital de Alagoas. O app reúne informação e entretenimento, com conteúdo interativo e em tempo real e foi desenvolvido nativamente para os sistemas iOS e Android."
          image={principal}
        >
          <Imagem image={veiculos}>
            <span>
              <p className="title color-black">Veículos de Comunicação</p>
              <p className="descricao">
                Canais de comunicação de informações via Rádio e TV, seguindo
                elevados padrões éticos e de credibilidade.
              </p>
            </span>
          </Imagem>
          <Imagem image={tv} imagePosition="left">
            <span>
              <p className="title color-red">Tv Pajuçara</p>
              <p className="descricao">
                Com o smartphone, é possível assistir a toda uma programação de
                canais disponíveis na TV Pajuçara.
              </p>
            </span>
          </Imagem>
          <Imagem color="rgba(0, 0, 0, 0.87)" image={radios} titulo="Rádios">
            <span>
              <p className="subtitle color-orange">Rádio Arapiraca</p>
              <p className="descricao">
                Programações das rádio Pajuçara FM Arapiraca 101,9.
              </p>
              <br />
              <p className="subtitle color-green">Avaliações</p>
              <p className="descricao">
                Programações das rádio Pajuçara FM Maceió 103,7.
              </p>
            </span>
          </Imagem>
          <Imagem image={reporter} imagePosition="left">
            <span>
              <p className="title color-blue">Você Repórter</p>
              <p className="descricao">
                Canal instantâneo e fácil para os usuários colaborarem com a
                Pscom via smartphone.
              </p>
            </span>
          </Imagem>
          <Imagem image={portal}>
            <span>
              <p className="title color-dark-blue">Portal de Notícias</p>
              <p className="descricao">
                Portal líder em acessos de Alagoas, informações seguindo
                elevados padrões éticos e de credibilidade.
              </p>
            </span>
          </Imagem>

          <DownloadApp
            ios="https://itunes.apple.com/br/app/paju%C3%A7ara/id987480808?mt=8"
            android="https://play.google.com/store/apps/details?id=br.com.pajucara"
          />
        </Info>

        <MaisProjetos>
          <Projeto
            image={aminthas}
            title="Cartório Aminthas Garcez"
            link="/servicos/desenvolvimento/cartorio-aminthas"
          />
          <Projeto
            image={amadeus}
            title="Colégio Amadeus"
            link="/servicos/desenvolvimento/amadeus"
          />
          <Projeto
            image={treinamentos}
            title="Getinfo Treinamentos"
            link="/servicos/desenvolvimento/treinamentos"
          />
          <Projeto
            image={piox}
            title="PioNET"
            link="/servicos/desenvolvimento/pionet"
          />
        </MaisProjetos>
      </div>
    );
  }
}

export default Pajucara;
