import React from "react";

//CSS
import "../assets/styles/components/imagemComponent.scss";

const Image = props => <img className="img" src={props.image} alt="" />;

const TextContent = props => (
  <div>
    <p className="titulo" style={{ color: props.color }}>
      {props.titulo}
    </p>
    <br />
    {props.children}
  </div>
);

const Item = props => {
  return props.imagePosition === "left" ? (
    <div id="imagem-component">
      <div className="columns is-centered espacamento">
        <div className="column is-3">
          <Image image={props.image} />
        </div>
        <div className="column is-4 descricao">
          <TextContent color={props.color} titulo={props.titulo}>
            {props.children}
          </TextContent>
        </div>
      </div>
    </div>
  ) : (
    <div id="imagem-component">
      <div className="columns is-centered espacamento">
        <div className="column is-4 descricao">
          <TextContent color={props.color} titulo={props.titulo}>
            {props.children}
          </TextContent>
        </div>
        <div className="column is-3">
          <Image image={props.image} />
        </div>
      </div>
    </div>
  );
};

export default Item;
