import React, {Component} from 'react';

//Componentes
import Card            from './../desenvolvimento/CardComponent';
import AndroidIosWeb   from './../desenvolvimento/AndroidIosWeb';
import Button            from './../../general/Button';

//images
import image      from '../../../assets/images/desenvolvimento/clubedevantagens.png';

class ClubeVantagens extends Component{
  render(){
    return(
      <div className="animated fadeInUp">
        <Card
          color="#ff1956"
          title="Clube de Vantagens"
          text={`A plataforma Clube de Vantagens permite aos usuários novas experiências no espaço digital. Através do site e aplicativo é possível gerenciar todos os descontos de maneira simples, rápida e aproveitar ainda mais as vantagens do clube.`}
          icons={<AndroidIosWeb />}
          column="8"
          image={image}
        >
          <Button disabled>Em breve</Button>
        </Card>
      </div>
    );
  }
}

export default ClubeVantagens;