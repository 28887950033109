import React, { Component } from "react";
import Servico from "../components/Servicos/ServiceComponent";
import TreinamentosCard from "../components/treinamentos/TreinamentosCard";
import Ad from "../components/Ad";
import Button from "../components/general/Button";
import "../assets/styles/views/treinamentos.scss";

import adTreinamentoApp from "../assets/images/treinamentos/treinamento-app.png";
// import android from "../assets/images/treinamentos/android.jpg";
// import iso20000 from "../assets/images/treinamentos/iso20000.jpg";
// import iso27002 from "../assets/images/treinamentos/iso27002.jpg";
// import itil from "../assets/images/treinamentos/itil.jpg";
import treinamento from "../assets/images/treinamentos/1.jpg";

class Treinamentos extends Component {
  render() {
    return (
      <div id="treinamentos-list">
        <Servico
          icon="description"
          title="Treinamentos"
          descricao={`Invista em sua carreira e aperfeiçoe seu conhecimento, venha para o time Getinfo. Aqui nós temos o treinamento que você precisa para tirar sua certificação.`}
        />
        <div className="section margin-bottom animated fadeInUp">
          <div className="container">
            <TreinamentosCard
              image={treinamento}
              title="Treinamento - THINKING AGILE (GESTÃO ÁGIL)"
              colorTitle="#5c9019"
              description="Você já utiliza um modelo de Gestão Ágil para conceber seus projetos em interações curtas, entregas contínuas com um time multifuncional? Se a sua resposta foi não, a Get_info pode te ajudar. "
              link="treinamentos/agile"
            />
            {/* <TreinamentosCard
              image={android}
              title="Treinamento - Android: Módulo 1"
              colorTitle="#5c9019"
              description="O curso de Android aborda o desenvolvimento com Android SDK desde conceitos básicos até recursos avançados. Durante o curso, desenvolvemos uma aplicação completa que usa recursos de formulários, Google Maps, comunicação com servidor, persistência local, GPS, entre outros. Desenvolvemos tanto para celular quanto para tablets, abordando também a última versão Android. "
              link="treinamentos/android"
            />
            <TreinamentosCard
              image={iso20000}
              title="Treinamento - ISO 20000"
              colorTitle="#274290"
              description="Treinamento preparatório para Exame de Certificação ISO 20000 Foundation. Os participantes aprenderão os elementos e princípios essenciais da norma ISO/IEC 20000."
              link="treinamentos/iso-20000"
            />
            <TreinamentosCard
              image={iso27002}
              title="Treinamento - ISO 27002"
              colorTitle="#274290"
              description="Treinamento preparatório para Exame de Certificação ISO 27002 Foundation. Tem como objetivo apresentar aos participantes os requisitos da norma ISO/IEC 27000 perante um Sistema de Segurança da informação, a medida que os princípios básicos da gestão de segurança da informação são abordados. "
              link="treinamentos/iso-27002"
            />
            <TreinamentosCard
              image={itil}
              title="Treinamento - ITIL Foundation"
              colorTitle="#7d3d64"
              description="Esse treinamento tem como objetivo contextualizar sobre o Gerenciamento de Serviços de TI (ITSM), a biblioteca de melhores práticas ITIL®, uma visão geral dos princípios e conceitos da gestão da ITIL®, a terminologia, além de processos e habilidades necessários para desenvolver e manter serviços que são ao mesmo tempo valiosos e econômicos, tanto para os clientes quanto para os provedores de serviços.   "
              link="treinamentos/itil"
            /> */}
          </div>
        </div>
        <hr />
        <Ad
          image={adTreinamentoApp}
          title={`Conheça o aplicativo de treinamentos`}
        >
          <Button link="/servicos/desenvolvimento/treinamentos">
            Saiba mais <i className="material-icons">arrow_forward</i>
          </Button>
        </Ad>
      </div>
    );
  }
}

export default Treinamentos;
