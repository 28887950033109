import React, { Component } from "react";
import Servico from "../components/Servicos/ServiceComponent";
import Treinamento from "../components/treinamentos/Treinamento";
import "../assets/styles/views/treinamentos.scss";

class TreinamentoAgile extends Component {
  render() {
    return (
      <div id="treinamentos">
        <Servico
          icon="description"
          title="Treinamento - THINKING AGILE (GESTÃO ÁGIL)"
          colorTitle="#5c9019"
          descricao={`Você já utiliza um modelo de Gestão Ágil para conceber seus projetos em interações curtas, entregas contínuas com um time multifuncional? Se a sua resposta foi não, a Get_info pode te ajudar.`}
        />
        <hr />
        <div className="section animated fadeInUp">
          <div className="container">
            <Treinamento
              warning
              dataTurma="Devido à pandemia do COVID 19 estamos adaptando nosso treinamento para a versão on-line e, posteriormente, divulgaremos as novas datas."
              investimento="R$690,00 / R$600,00 (Boleto bancário) / 10% de Desconto (Cartão 1X) / Inscrições em grupo tem desconto."
              horario="8h – 12h e 14h – 18h."
              instrutorNome="Adriana Fontes"
              instrutorDescricao="Adriana Fontes é mestra em Ciência da Computação pela Universidade 
              Federal de Sergipe, possui MBA em Gerência de Projetos cursado na Faculdade de 
              Administração de Negócios de Sergipe (FANESE) e especializações em Gestão de 
              Projetos de Software na FANESE e Didática do Ensino Superior na Associação de 
              Ensino e Cultura Pio Décimo. Cursou Bacharelado em Sistemas de Informação na 
              Universidade Tiradentes e Técnico em Informática com Ênfase em Desenvolvimento 
              de Sistemas no Colégio Pio Décimo. 
              É Certified Scrum Professional - ScrumMaster, Certified Scrum Professional - Product Owner e Certified Lean Kanban pela LeanKanban Universit. 
              Exerceu os cargos e funções de Programadora de Sistemas, Analista de Sistemas, Product Owner e Scrum Master em diversos projetos na Associação de Ensino e Cultura Pio Décimo 
              por quase 10 anos. Coordenou e lecionou diversas disciplinas nos cursos de Graduação em Pedagogia e Técnico em Informática na Faculdade e no Colégio Pio Décimo respectivamente.
              Lecionou no curso Bacharelado em Sistemas de Informação no Instituto Federal de Sergipe - Campus Lagarto. Coordenou o curso de Tecnologia em Defesa Cibernética na Faculdade UNIRB -
              Campus Aracaju. Atuou como Consultora, Gerente de Projetos e Scrum Master na empresa Trust Consultoria e Softwares por 8 anos. Em 2019 fez 8 meses de intercâmbio no Canadá onde estudou inglês na 
              International Language Academy of Canada em Toronto. E, Atualmente, atua como Consultora na empresa Get_Info Soluções Corporativas."
              objetivo="Nosso treinamento Thinking Agile contempla técnicas e métodos do 
              Design Thinking para te ajudar a materializar sua ideia e, em seguida,
              o Framework Scrum somado ao conceito de Squads para desenvolver 
              e colocar no mercado seu produto ou serviço."
              publicoAlvo="Estudantes, gestores e profissionais em geral."
              conteudoProgramatico={
                "Cultura Ágil e Gestão Ágil de Projetos.\nManifesto Ágil, Princípios e Valores do Manifesto Ágil.\nFramework Scrum:\n   Time   Scrum   -   Product   Owner,   Scrum   Master, Time   de  Desenvolvimento.\n Eventos do Scrum - Sprint, Sprint Planning, Daily Scrum, Sprint  Review, Sprint Retrospective, Release.\n Artefatos do Scrum - Product Backlog, Sprint Backlog, Definição de Pronto, Incremento  do  Produto, Metas de Negócios, Gráficos de Acompanhamento do Trabalho, Definição de Preparado.\nMaterializando a Visão do Produto.\nTécnicas e Métodos do Design Thinking.\nSquads, Tribos, Chapter.\nCertificações da Scrum Alliance.\nTreinamento   com   diversas   dinâmicas   e   práticas   que   objetivam aumentar  o engajamento dos participantes e a assimilação do conteúdo."
              }
              habilitado={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TreinamentoAgile;
