import React, { Component } from "react";

import ScrollAnimation from 'react-animate-on-scroll';

// Assets
import background from "./../../assets/images/bg1.png";

export default class Banner extends Component {
  render() {
    return (
      <ScrollAnimation animateIn="fadeIn">
        <div id="banner">
          <div className="bg" style={{ background: `url(${background}) center  center / cover` }}>
            <div className="section">
              <div className="container">
                <ScrollAnimation animateIn="jackInTheBox">
                  <h1>Você tem a ideia, <br className="is-hidden-mobile"/>nós temos a solução.</h1>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    );
  }
}
