import React, { Component } from "react";

//Componentes
import Imagem from "../../../ImagemComponent";
import Info from "../InfoComponent";
import MaisProjetos from "./MaisProjetos";
import Projeto from "./Projeto";
import DownloadApp from "./../../../DownloadApp";

//Imagens
import app from "../../../../assets/images/desenvolvimento/PioNet/pionet6.png";
import avisos from "../../../../assets/images/desenvolvimento/PioNet/pionet5.png";
import horario from "../../../../assets/images/desenvolvimento/PioNet/pionet2.png";
import notas from "../../../../assets/images/desenvolvimento/PioNet/pionet3.png";
import pendencias from "../../../../assets/images/desenvolvimento/PioNet/pionet4.png";
import principal from "../../../../assets/images/desenvolvimento/PioNet/pionet1.png";

import amadeus from "../../../../assets/images/desenvolvimento/MaisProjetos/amadeus.png";
import aminthas from "../../../../assets/images/desenvolvimento/MaisProjetos/aminthas.png";
import pajucara from "../../../../assets/images/desenvolvimento/MaisProjetos/pajucara.png";
import treinamentos from "../../../../assets/images/desenvolvimento/MaisProjetos/treinamentos.png";

//CSS
import "../../../../assets/styles/components/Servicos/desenvolvimento/pioNet.scss";

class PioNet extends Component {
  render() {
    return (
      <div id="pionet" className="animated fadeIn">
        <Info
          title="PioNET"
          color="rgba(0, 0, 0, 0.87)"
          descricao="Sempre buscando avançar no tocante a tecnologia, a faculdade Pio Décimo disponibiliza o PioNET Mobile para Android e iOS. Esse app disponibiliza para os alunos da faculdade o acesso à: notas e faltas, horários, avisos e pendências."
          image={principal}
        >
          <Imagem image={app}>
            <span id="pionet">
              <p className="title">App PioNet</p>
              <p className="descricao">
                Os alunos da faculdade Pio Décimo agora possuem um aplicativo
                para poder visualizar horários, notas, faltas, pendências e
                avisos.
              </p>
            </span>
          </Imagem>
          <Imagem
            color="rgba(0, 0, 0, 0.87)"
            image={horario}
            imagePosition="left"
          >
            <span id="pionet">
              <p className="title">Horário</p>
              <p className="descricao">
                É possível visualizar toda a grade do período corrente do aluno.
                Informando os dias e horários em que o aluno tem determinada
                aula.
              </p>
            </span>
          </Imagem>
          <Imagem image={notas}>
            <span id="pionet">
              <p className="title">Notas e Faltas</p>
              <p className="descricao">
                Com o smartphone, é possível saber quais foram as faltas e notas
                do período corrente do aluno.
              </p>
            </span>
          </Imagem>
          <Imagem image={pendencias} imagePosition="left">
            <span id="pionet">
              <p className="title">Pendências</p>
              <p className="descricao">
                É possível checar se há alguma pendência por parte do aluno com
                a instituição.
              </p>
            </span>
          </Imagem>
          <Imagem image={avisos}>
            <span id="pionet">
              <p className="title">Avisos</p>
              <p className="descricao">
                Assim que o docente posta um aviso, o discente recebe uma
                notificação. De forma rápida e prática, sem complicações.
              </p>
            </span>
          </Imagem>

          <DownloadApp
            ios="https://itunes.apple.com/br/app/pionet/id1016965337?mt=8"
            android="https://play.google.com/store/apps/details?id=getinfo.pionet"
          />
        </Info>
        <MaisProjetos>
          <Projeto
            image={aminthas}
            title="Cartório Aminthas Garcez"
            link="/servicos/desenvolvimento/cartorio-aminthas"
          />
          <Projeto
            image={amadeus}
            title="Colégio Amadeus"
            link="/servicos/desenvolvimento/amadeus"
          />
          <Projeto
            image={treinamentos}
            title="Getinfo Treinamentos"
            link="/servicos/desenvolvimento/treinamentos"
          />
          <Projeto
            image={pajucara}
            title="Pajuçara"
            link="/servicos/desenvolvimento/pajucara"
          />
        </MaisProjetos>
      </div>
    );
  }
}

export default PioNet;
