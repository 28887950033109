import React, { Component } from "react";

//Componentes
import AndroidIos from "./../desenvolvimento/AndroidIos";
import Button from "./../../general/Button";
import Card from "./../desenvolvimento/CardComponent";

//images
import treinamentos from "../../../assets/images/desenvolvimento/treinamentos.png";

//CSS
import "../../../assets/styles/components/Servicos/desenvolvimento/getinfoTreinamentos.scss";

class GetinfoTreinamentos extends Component {
  render() {
    return (
      <div id="getinfo-treinamentos" className="animated fadeInUp">
        <Card
          color="black"
          title="Getinfo Treinamentos"
          text={`Getinfo Treinamentos faz você relembrar seu conteúdo estudado através de questionários direcionados. Simule provas de certificações importantes, quebre a cabeça lembrando dos assuntos do seu dia a dia profissional ou então saiba quais assuntos são necessários para se aprofundar no conhecimento que você deseja.`}
          icons={<AndroidIos />}
          column="6"
          image={treinamentos}
        >
          <Button link="/servicos/desenvolvimento/treinamentos">
            Ver mais
          </Button>
        </Card>
      </div>
    );
  }
}

export default GetinfoTreinamentos;
