import React, { Component } from "react";
import {
  Button,
  Container,
  Navbar,
  NavbarBrand,
  NavbarItem,
  NavbarBurger,
  NavbarMenu,
  NavbarEnd,
  NavbarLink,
  NavbarDropdown
} from "bloomer";
import { NavLink } from "react-router-dom";

// Components
//import Chatbot from "../chatbot/Chatbot";

// Assets
import logo from "./../../assets/images/logo-getinfo2.png";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false
    };

    this.onClickNav = this.onClickNav.bind(this);
  }

  onClickNav() {
    this.setState({ isActive: !this.state.isActive });
  }

  render() {
    return (
      <header>
        <Navbar className="is-fixed-top animated fadeIn">
          <Container>
            <NavbarBrand>
              <NavbarItem>
                <NavLink className="navbar-item" to="/">
                  <img src={logo} alt="" />
                </NavLink>
              </NavbarItem>
              <NavbarBurger
                isActive={this.state.isActive}
                onClick={this.onClickNav}
              />
            </NavbarBrand>
            <NavbarMenu
              isActive={this.state.isActive}
              onClick={this.onClickNav}
            >
              <NavbarEnd>
                <NavbarItem hasDropdown isHoverable>
                  <NavbarLink className="uppercase">Serviços</NavbarLink>
                  <NavbarDropdown>
                    <NavLink
                      className="navbar-item is-tab"
                      to="/servicos/desenvolvimento"
                    >
                      <i className="material-icons">devices_other</i>
                      Desenvolvimento de Software
                    </NavLink>
                    <NavLink
                      className="navbar-item is-tab"
                      to="/servicos/business"
                    >
                      <i className="material-icons">multiline_chart</i>
                      Business Intelligence
                    </NavLink>
                    <NavLink
                      className="navbar-item is-tab"
                      to="/servicos/governanca"
                    >
                      <i className="material-icons">business</i>
                      Governança de TI
                    </NavLink>
                  </NavbarDropdown>
                </NavbarItem>
                <NavLink className="navbar-item uppercase" to="/treinamentos">
                  Treinamentos
                </NavLink>
                <NavLink className="navbar-item uppercase" to="/blog">
                  Blog
                </NavLink>
                <NavLink
                  className="navbar-item uppercase"
                  to={{pathname: "https://getinfo.pandape.infojobs.com.br/"}} target="_blank"
                >
                  Trabalhe Conosco
                </NavLink>
                <NavLink className="navbar-item uppercase" to="/covid-19">
                  COVID-19
                </NavLink>
                <NavLink className="navbar-item" to="/contato">
                  <Button>Contato</Button>
                </NavLink>
              </NavbarEnd>
            </NavbarMenu>
          </Container>
        </Navbar>
        {/*<Chatbot />*/}

        <div style={{ marginBottom: "64px" }}></div>
      </header>
    );
  }
}

export default Header;
