import React, { Component } from "react";

//CSS
import "../../../assets/styles/components/Servicos/desenvolvimento/androidIosWeb.scss";

//Material Design Icons
import Apple from "mdi-react/AppleIcon";

class AndroidIosWeb extends Component {
  render() {
    return (
      <div id="android-ios-web">
        <div align="center" className="columns is-centered espacamento">
          <div className="column is-2 ">
            <Apple className="apple" />
            <span className="texto">iOS</span>
          </div>
          <div className="column is-2 is-3-tablet">
            <i className="material-icons icone">android</i>
            <span className="texto">Android</span>
          </div>
          <div className="column is-2 ">
            <i className="material-icons icone">code</i>
            <span className="texto">Web</span>
          </div>
        </div>
      </div>
    );
  }
}

export default AndroidIosWeb;
