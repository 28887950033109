import React, { Component } from "react";

//CSS
import "../assets/styles/pages/business.scss";

//Componentes
import Servico from "../components/Servicos/ServiceComponent";
import FaleConosco from "../components/Servicos/FaleConoscoComponent";
import Imagem from "../components/Servicos/business/ImagensBusiness";

//Imagens
import Qlik from "../assets/images/business/qlik.png";
import bi1 from "../assets/images/business/bi1.png";
import bi2 from "../assets/images/business/bi2.png";
import bi3 from "../assets/images/business/bi3.png";
import marcas from "../assets/images/business/marcas.png";
import banese from "../assets/images/business/banese.png";
import pscom from "../assets/images/business/pscom.png";
import progresso from "../assets/images/business/progresso.png";

class Business extends Component {
  render() {
    return (
      <div id="business">
        <Servico
          icon="multiline_chart"
          title="Business Intelligence"
          descricao={`A plataforma de Business Discovery Qlik é uma solução de BI 
                      orientada pelo usuário, que permite a tomada de decisões de 
                      forma rápida e inteligente. Inclui fazer coisas como:`}
        >
          <div className="container animated fadeInUp">
            <div id="qlik" className="columns is-centered">
              <div className="column is-2 div-logo">
                <img src={Qlik} alt="" className="logo" />
              </div>
              <div className="column is-4 lista">
                <li>
                  <span>
                    Desenvolvimento de indicadores-chave de desempenho (KPIs);
                  </span>
                </li>
                <li>
                  <span>Análise de tendências;</span>
                </li>
                <li>
                  <span>Modelagem Preditiva;</span>
                </li>
                <li>
                  <span>Painéis.</span>
                </li>
              </div>
            </div>

            <div id="visao-geral">
              <div className="columns is-centered">
                <p className="has-text-centered titulo-section">
                  Visão geral da ferramenta
                </p>
              </div>
              <Imagem
                imagePosition="left"
                title="Decisões precisas e com economia de tempo"
                text={`A tomada de decisão eficaz baseia-se em ter a informação certa disponível e de fácil acesso. 
                        Levando apenas alguns minutos para aprender, as associações automáticas do Qlik criam 
                        infinitas possibilidades de fazer consultas sem exigir estruturas e hierarquias tediosas, 
                        como é típico em outras ferramentas de análise de dados.`}
                image={bi1}
              />
              <Imagem
                title="Entenda como os dados estão associados"
                text={`O Qlik permite que os usuários obtenham informações inesperadas do negócio ao entender
                    como os dados estão associados e quais dados não estão relacionados.`}
                image={bi2}
              />
              <Imagem
                imagePosition="center"
                title="Interfaces de usuário simples e diretas"
                text={`Ele traz um novo nível de análise, insight e valor para os armazenamentos de dados 
                    existentes com interfaces de usuário que são limpas, simples e diretas.`}
                column="12"
                image={bi3}
              />
              <Imagem
                imagePosition="center"
                title="Integração com todas as bases de dados"
                text={`Unifique fontes de dados para uma visão completa de informações, sem intervalos, 
                    e torne mais fácil descobrir insights ocultos.`}
                column="4"
                image={marcas}
              />
            </div>
          </div>
        </Servico>
        <hr className="hr-custom" />

        {/*<div id="cases-de-sucesso">
          <div className="section">
            <div className="container">
              <p className="has-text-centered titulo-section">Cases de Sucesso</p>
              <div className="columns is-vcentered box">
                <div className="column is-4 has-text-centered">
                  <img src={banese} alt=""/>
                </div>
                <div className="column is-4 has-text-centered">
                <img src={progresso} alt=""/>
                </div>
                <div className="column is-4 has-text-centered">
                  <img src={pscom} alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>*/}

        <div id="cases-de-sucesso" className="animated fadeInUp">
          <div className="section">
            <div className="container">
              <p className="has-text-centered titulo-section">
                Cases de Sucesso
              </p>
              <div className="box">
                <div className="columns is-vcentered">
                  <div className="column is-3 has-text-centered img-div">
                    <img src={banese} alt="" />
                  </div>
                  <div className="column is-9 text-div">
                    <p className="text-cases">
                      Aplicação que permite aos gestores ter uma visão detalhada
                      do perfil dos seus clientes e também analisar as
                      tendências relacionadas ao seu comportamento. Alem disso,
                      é possível acompanhar as transações realizadas nos canais
                      de atendimento, como também, avaliar indicadores da
                      carteira de crédito, tais como a quantidade de contratos
                      existentes, valores liquidados, dentre outros.
                    </p>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="columns is-vcentered">
                  <div className="column is-3 has-text-centered img-div">
                    <img src={pscom} alt="" />
                  </div>
                  <div className="column is-9 text-div">
                    <p className="text-cases">
                      Painel elaborado para acompanhar a situação geral das
                      vendas de espaços publicitários dos veículos do grupo
                      Pajuçara, assim como avaliar a performance da equipe de
                      vendas.
                    </p>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="columns is-vcentered">
                  <div className="column is-3 has-text-centered img-div">
                    <img src={progresso} alt="" />
                  </div>
                  <div className="column is-9 text-div">
                    <p className="text-cases">
                      MKBF (Mean Kilometer Between Failure) - Aplicação para o
                      acompanhamento da quantidade de quilômetros que um carro
                      pode andar até apresentar uma falha, além de apresentar as
                      linhas em que os ônibus quebram mais, quais motoristas dão
                      mais entradas nas oficinas.
                      <br />
                      <br />
                      Previsão da arrecadação mensal – Painel que emite uma
                      projeção da arrecadação mensal baseada nos dias do mês que
                      já possuem os dados.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <FaleConosco
          textButton={`Se você gostaria de ter essa solução, fale conosco para mais detalhes:`}
        />
      </div>
    );
  }
}

export default Business;
