import React, { Component } from "react";
import { Columns, Column } from "bloomer";
import { ChevronDownIcon, ChevronUpIcon } from "mdi-react";

import ScrollAnimation from "react-animate-on-scroll";

// Assets
import banese from "./../../assets/images/clientes/banese.png";
import banese_card from "./../../assets/images/clientes/banese-card.png";
import cartorio from "./../../assets/images/clientes/cartorio.png";
import cas from "./../../assets/images/clientes/cas.png";
import cio from "./../../assets/images/clientes/cio.png";
import instituto from "./../../assets/images/clientes/instituto-banese.png";
import incra from "./../../assets/images/clientes/incra.png";
import nassal from "./../../assets/images/clientes/nassal.png";
import pajucara from "./../../assets/images/clientes/pajucara.png";
import piodecimo from "./../../assets/images/clientes/piodecimo.png";
import ricarte from "./../../assets/images/clientes/ricarte.png";
import sebrae from "./../../assets/images/clientes/sebrae.png";
import sercon from "./../../assets/images/clientes/sercon.png";
import trt from "./../../assets/images/clientes/trt20.png";
import ufpi from "./../../assets/images/clientes/ufpi.png";
import uni from "./../../assets/images/clientes/uni.png";
import unit from "./../../assets/images/clientes/unit.png";
import vale from "./../../assets/images/clientes/vale.png";
import laticinios from "./../../assets/images/clientes/Laticinios-cinza.svg";
import tre from "./../../assets/images/clientes/tre.jpg";
import balacobaco from "./../../assets/images/clientes/balacobaco.png";
import amazing_acai from "./../../assets/images/clientes/amazing_acai.png";
import crea from "./../../assets/images/clientes/crea.png";
import decos from "./../../assets/images/clientes/decos.png";
import fsge from "./../../assets/images/clientes/fsge.png";
import tre_se from "./../../assets/images/clientes/tre-se.png";
import uca from "./../../assets/images/clientes/uça.png";

class Clientes extends Component {
  state = {
    fullList: false,
  };

  handleClickList = () => {
    if (this.state.fullList) {
      this.setState({ fullList: false });
    } else {
      this.setState({ fullList: true });
    }
  };

  render() {
    var styleList = this.state.fullList ? "fadeIn" : "fadeOut";

    return (
      <div id="clientes" className="animated fadeIn">
        <div className="section">
          <div className="container">
            <Columns>
              <Column>
                <ScrollAnimation animateIn="fadeIn">
                  <h1>Nossos clientes</h1>
                </ScrollAnimation>
              </Column>
            </Columns>
            <ScrollAnimation animateIn="fadeInUp">
              <Columns className="center-div column1">
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={banese} className="maxHeight50" alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={banese_card} className="maxHeight40" alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={instituto} className="maxHeight40" alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={cartorio} alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={cas} className="maxHeight50" alt="" />
                </Column>
              </Columns>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp">
              <Columns className="center-div column2">
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={pajucara} alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={piodecimo} className="maxHeight90" alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={sebrae} alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={sercon} className="maxHeight50" alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={incra} className="maxHeight30" alt="" />
                </Column>
              </Columns>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp">
              <Columns className="center-div column3">
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={trt} className="maxHeight50" alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={ufpi} className="maxHeight90" alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={unit} className="maxHeight50" alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={vale} className="maxHeight50" alt="" />
                </Column>
              </Columns>
            </ScrollAnimation>

            {/* Lista Completa */}
            <div className={styleList}>
              <Columns className="center-div column4">
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={uni} className="maxHeight50" alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={cio} className="maxHeight50" alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={nassal} className="maxHeight50" alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "2" }}
                  className="center-item"
                >
                  <img src={ricarte} className="maxHeight70" alt="" />
                </Column>
              </Columns>
              <Columns className="center-div column4">
                <Column
                  isSize={{ mobile: "12", desktop: "3" }}
                  className="center-item"
                >
                  <img
                    src={laticinios}
                    className="maxHeight50"
                    alt=""
                  />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "3" }}
                  className="center-item"
                >
                  <img src={tre_se} className="grayscale maxHeight50" alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "3" }}
                  className="center-item"
                >
                  <img
                    src={balacobaco}
                    className="grayscale maxHeight50"
                    alt=""
                  />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "3" }}
                  className="center-item"
                >
                  <img
                    src={amazing_acai}
                    className="grayscale maxHeight70"
                    alt=""
                  />
                </Column>
              </Columns>
              <Columns className="center-div column4">
                <Column
                  isSize={{ mobile: "12", desktop: "3" }}
                  className="center-item"
                >
                  <img src={crea} className="grayscale maxHeight50" alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "3" }}
                  className="center-item"
                >
                  <img src={decos} className="grayscale maxHeight50" alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "3" }}
                  className="center-item"
                >
                  <img src={fsge} className="grayscale maxHeight50" alt="" />
                </Column>
                <Column
                  isSize={{ mobile: "12", desktop: "3" }}
                  className="center-item"
                >
                  <img src={uca} className="grayscale maxHeight70" alt="" />
                </Column>
              </Columns>
            </div>

            <Columns>
              <Column className="full-list-button show-list">
                <ScrollAnimation animateIn="fadeInUp" delay={1000}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={this.handleClickList}
                  >
                    {this.state.fullList
                      ? `Ver lista reduzida`
                      : `Ver lista completa`}{" "}
                    {this.state.fullList ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    )}
                  </span>
                </ScrollAnimation>
              </Column>
            </Columns>
          </div>
        </div>
      </div>
    );
  }
}

export default Clientes;
