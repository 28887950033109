import React, { Component } from "react";
import "../assets/styles/views/blog.scss";
import ArtigoDestaque from "./../components/blog/ArtigoDestaque";
import axios from "axios";

class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      posts: [],
      postFirst: [],
      postsLeft: [],
      postsRight: [],
      currentPage: 1,
      totalPages: 0
    };
  }

  componentDidMount() {
    this.getPosts();
  }

  getPosts = () => {
    this.setState({ postFirst: [] });
    this.setState({ postsRight: [] });
    this.setState({ postsLeft: [] });

    axios
      .get(
        `${process.env.REACT_APP_API_BLOG_URI}/posts?_embed&page=${this.state.currentPage}`
      )
      .then((response) => {
        this.setState({ totalPages: response.headers["x-wp-totalpages"] });
        this.setState({ posts: response.data });
        response.data.forEach(
          function(post, i) {
            // Se tiver na primeira página, a primeira notícia fica em destaque...
            if (this.state.currentPage === 1) {
              if (i === 0) {
                this.setState({ postFirst: [...this.state.postFirst, post] });
              } else {
                if (i % 2 === 0) {
                  this.setState({
                    postsRight: [...this.state.postsRight, post]
                  });
                } else {
                  this.setState({ postsLeft: [...this.state.postsLeft, post] });
                }
              }
            } else {
              if (i % 2 === 0) {
                this.setState({ postsLeft: [...this.state.postsLeft, post] });
              } else {
                this.setState({ postsRight: [...this.state.postsRight, post] });
              }
            }
          }.bind(this)
        );
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };

  nextPage = () => {
    this.setState({ isLoading: true });
    // eslint-disable-next-line
    this.setState({ currentPage: ++this.state.currentPage });
    this.getPosts();
    window.scrollTo(0, 0);
  };

  previousPage = () => {
    this.setState({ isLoading: true });
    // eslint-disable-next-line
    this.setState({ currentPage: --this.state.currentPage });
    this.getPosts();
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div id="blog" className="animated fadeIn">
        <div className="section">
          <div className="container">
            {this.state.isLoading === true ? (
              /* CARREGANDO AS NOTICIAS... */
              <div className="columns is-centered card-alert">
                <div className="column is-6">
                  <div className="box">
                    <div className="center-icon">
                      <i className="material-icons icon">more_horiz</i>
                    </div>
                    <p>Carregando notícias...</p>
                  </div>
                </div>
              </div>
            ) : (
              /* NOTÍCIAS CARREGADAS! */
              <div>
                {this.state.posts.length === 0 ? (
                  /* NÃO TEM NENHUMA NOTÍCIA */
                  <div className="columns is-centered card-alert animated fadeIn">
                    <div className="column is-6">
                      <div className="box">
                        <div className="center-icon">
                          <i className="material-icons icon">error_outline</i>
                        </div>
                        <p>Nenhuma notícia publicada até o momento.</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {/* TEM NOTÍCIA PARA LISTAR - APENAS TABLETS E DESKTOPS */}
                    <div className="is-hidden-mobile animated fadeIn">
                      {/* POST DE DESTAQUE */}
                      {this.state.currentPage === 1 && (
                        <div>
                          <div className="columns is-centered">
                            {this.state.postFirst.map((post, i) => (
                              <ArtigoDestaque
                                key={i}
                                src={
                                  post._embedded["wp:featuredmedia"]
                                    ? post._embedded["wp:featuredmedia"]["0"]
                                        .media_details &&
                                      post._embedded["wp:featuredmedia"]["0"]
                                        .media_details.sizes.full.source_url
                                    : null
                                }
                                alt=""
                                title={post.title.rendered}
                                text={post.excerpt.rendered}
                                link={`/blog/${post.slug}/${post.id}/`}
                              />
                            ))}
                          </div>
                        </div>
                      )}

                      <div className="tile is-ancestor">
                        {/* POSTS DO LADO ESQUERDO DA TELA */}
                        <div className="tile is-6 is-vertical is-parent">
                          {this.state.postsLeft.map((post, i) => (
                            <div key={i}>
                              <ArtigoDestaque
                                key={i}
                                src={
                                  post._embedded["wp:featuredmedia"]
                                    ? post._embedded["wp:featuredmedia"]["0"]
                                        .media_details &&
                                      post._embedded["wp:featuredmedia"]["0"]
                                        .media_details.sizes.full.source_url
                                    : null
                                }
                                alt=""
                                title={post.title.rendered}
                                text={post.excerpt.rendered}
                                link={`/blog/${post.slug}/${post.id}/`}
                              />
                            </div>
                          ))}
                        </div>

                        {/* POSTS DO LADO DIREITO DA TELA */}
                        <div className="tile is-6 is-vertical is-parent">
                          {this.state.postsRight.map((post, i) => (
                            <div key={i}>
                              <ArtigoDestaque
                                key={i}
                                src={
                                  post._embedded["wp:featuredmedia"]
                                    ? post._embedded["wp:featuredmedia"]["0"]
                                        .media_details &&
                                      post._embedded["wp:featuredmedia"]["0"]
                                        .media_details.sizes.full.source_url
                                    : null
                                }
                                alt=""
                                title={post.title.rendered}
                                text={post.excerpt.rendered}
                                link={`/blog/${post.slug}/${post.id}/`}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* TEM NOTÍCIA PARA LISTAR - APENAS MOBILE */}
                    <div className="is-hidden-tablet animated fadeIn">
                      <div className="columns is-centered">
                        {this.state.posts.map((post, i) => (
                          <div key={i}>
                            <ArtigoDestaque
                              key={i}
                              src={
                                post._embedded["wp:featuredmedia"]
                                  ? post._embedded["wp:featuredmedia"]["0"]
                                      .media_details &&
                                    post._embedded["wp:featuredmedia"]["0"]
                                      .media_details.sizes.full.source_url
                                  : null
                              }
                              alt=""
                              title={post.title.rendered}
                              text={post.excerpt.rendered}
                              link={`/blog/${post.slug}/${post.id}/`}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Paginação */}
          {this.state.totalPages > 1 && (
            <div className="next-previous column is-8-desktop is-12-mobile is-offset-2-desktop">
              <nav className="pagination is-centered" aria-label="pagination">
                <div className="column is-12-desktop is-12-mobile">
                  {this.state.currentPage > 1 ? (
                    <a
                      href="#!"
                      className="pagination-previous button-leaked-active"
                      onClick={this.previousPage}
                    >
                      {"< Anterior"}
                    </a>
                  ) : null}
                  {this.state.currentPage < this.state.totalPages ? (
                    <a
                      href="#!"
                      className="pagination-next button-leaked-active"
                      onClick={this.nextPage}
                    >
                      {"Próxima >"}
                    </a>
                  ) : null}
                </div>
              </nav>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Blog;
