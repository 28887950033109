import React from "react";

//CSS
import "../../assets/styles/components/Servicos/serviceComponent.scss";

const Servico = props => {
  return (
    <div id="servico" className="animated fadeIn">
      <div className="section">
        <div className="container">
          <div className="columns is-centered pagina">
            <div className="column has-text-centered is-6">
              {props.colorIcon ? (
                <i
                  className="material-icons icone"
                  style={{ color: props.colorIcon }}
                >
                  {props.icon}
                </i>
              ) : (
                <i className="material-icons icone gradient-color">
                  {props.icon}
                </i>
              )}
              <span
                className="is-pulled titulo"
                style={{ color: props.colorTitle }}
              >
                {props.title}
              </span>
            </div>
          </div>
          <div className="columns is-centered pagina">
            <div className="column has-text-centered is-6">
              <p className="texto">{props.descricao}</p>
            </div>
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default Servico;
